<template>
  <v-data-table
    class="custom-table"
    :headers="headers"
    :items-per-page="pagination"
    :items="items"
    :search="search"
    :footer-props="footerProps"
    @click:row="showItem"
    :sort-by="['start_date']"
    :sort-desc="true"
  >
    <template v-slot:top>
      <div class="pt-5">
      <div class="d-flex justify-end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-show="items.length && exportOption"
            outlined
            rounded
            small
            v-bind="attrs"
            v-on="on"
            :loading="loading"
        >

          <download-excel
                :data="items"
                :fields="json_fields"
                :type="'csv'"
                :name="'StatusPage_Records.csv'"
                :before-generate="setLoading"
                :before-finish="setLoading">
              Export
            </download-excel>
        </v-btn>
          </template>
          Export to CSV
        </v-tooltip>
      </div>
      <v-text-field
          v-model="search"
          label="Filter"
          class="mx-4"
      ></v-text-field>
      </div>
    </template>
    <template #[`item.incident_type`]="{item}">
      <span v-if="item.incident_type" class="text-capitalize">
        <component :is="dictionary[item.incident_type]" />
      </span>
      <span v-else>
        <maintenance/>
      </span>
    </template>
    <template #[`item.service_impacted`]="{item}">
      <span class="text-capitalize">
        {{ item.service_impacted }}
      </span>
    </template>
    <template #[`item.start_date`]="{item}">
      <span class="text-capitalize">
        {{ getDate(item.start_date) }}
      </span>

    </template>
    <template #[`item.end_date`]="{item}">
      <span class="text-capitalize">
        {{ getDate(item.end_date) }}
      </span>

    </template>
  </v-data-table>
</template>

<script>
import degradation from '@/components/tooltipIcons/degradation'
import thirdparty from '@/components/tooltipIcons/thirdparty'
import downtime from '@/components/tooltipIcons/downtime'
import suspected from '@/components/tooltipIcons/suspected'
import {mapGetters } from 'vuex'
import maintenance from '@/components/tooltipIcons/maintenanceIcon'

export default {
  name: 'Table',
  components: {
    degradation,
    downtime,
    maintenance,
    suspected,
    thirdparty
  },
  props: {
    headers: {
      type: Array,
      default: null
    },
    items: {
      type: Array,
      default: null
    },
    pagination: {
      type: Number,
      default: 1
    },
    footerProps: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: ''
    },
    exportOption: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    let self =this
    return {
      search: '',
      dictionary: {
        'Degradation': degradation,
        '3rd Party': thirdparty,
        'Downtime': downtime,
        'Maintenance': maintenance,
        'Suspected': suspected,
      },
      loading: false,
      json_fields: {
        ID: 'id',
        'Support Case': 'support_case',
        'Service Impacted': 'service_impacted',
        'Impact': 'impact',
        'Status': 'status',
        'Type': {
          field: 'incident_type', // nested attribute supported
          callback: (value) => {
            return value ? `${value}` : 'Maintenance';
          },
        },
        'Start Date': {
          field: 'start_date', // nested attribute supported
          callback: function (value)  {
            return `${self.getDate(value)}`;
          },
        },
        'End Date': {
          field: 'end_date', // nested attribute supported
          callback: function (value) {
            return `${self.getDate(value)}`;
          },
        },
      },
    }
  },
  computed:{
    ...mapGetters('Site', ['getTimeZone']),
    timezone(){
      return this.getTimeZone
    }
  },
  methods: {
    getDate (dateTs) {
      return this.$moment.tz(dateTs * 1000, this.timezone).format('YYYY-MM-DD hh:mm A')
    },
    setLoading(){
      this.loading = !this.loading
    },
    showItem (item) {
      const record = { ...item }
      const event = record.incident_type ? 'showIncident' : 'showMaintenance'
      this.$emit(event, record)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
